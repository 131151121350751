import React, { useState, useEffect, Fragment } from 'react';
import { CircularProgress } from '@material-ui/core';

import {SpinnerBackground, StyledSpinner} from './Styles'

const Spinner = () => {
    return (
        <SpinnerBackground>
            <StyledSpinner>

            </StyledSpinner>
        </SpinnerBackground>
    )
}

export default Spinner