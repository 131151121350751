import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import history from '../browserHistory';
import JobListPage from '../Containers/JobListPage'
import LoginPage from '../Containers/LoginPage'

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/authenticate" component={LoginPage} />
      <Route path="/" component={JobListPage} />
    </Switch>
  </Router>
);

export default Routes;