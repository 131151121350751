import { Typography, Box, Paper, TextField } from '@material-ui/core';

import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
    max-width: 40%;
    margin: auto;
    margin-top: 150px;
    padding: 40px;
`;

export const StyledTextField = styled(TextField)`
    margin-top: 10px !important;
    margin-bottom: 10px !important;
`;

export const ErrorText = styled(Typography)`
    color: red;
`;