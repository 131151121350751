import styled from 'styled-components';
import { Fragment } from 'react'
import { CircularProgress } from '@material-ui/core';

export const SpinnerBackground = styled('div')`
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 100000000%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
`;

export const StyledSpinner = styled(CircularProgress)`
    position: fixed;
    top: 30%;
    right: 49%;
    zIndex: 100;
    color: black;
`;

