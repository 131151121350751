import styled from 'styled-components';

import TableContainer from '@material-ui/core/TableContainer';
import { Paper, AppBar, Button, Toolbar, DialogContent } from '@material-ui/core';

export const StyledTableContainer = styled(TableContainer)`
    max-width: 50%;
    margin: auto;
`;

export const StyledPaper = styled(Paper)`
    max-width: 70%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px
`;

export const StyledAppBar = styled(AppBar)`
    justify-content: end;
    padding: 10px 5px 10px 5px
`

export const LogoutButton = styled(Button)`
    width: fit-content;
`

export const StyledToolBar = styled(Toolbar)`
    margin-left: auto;
`

export const SearchPaper = styled(Paper)`
    padding: '2px 4px';
    display: 'flex';
    align-items: 'center';
    width: 'fit-content';
`

export const DialogContentNoScroll = styled(DialogContent)`
    overflow-y: auto;
`