import React, { Fragment } from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import NormalizeStyles from './NormalizeStyles';
import Routes from './Routes';
import { ConfirmProvider } from "material-ui-confirm";

const App = () => (
    <Fragment>
      {/* <NormalizeStyles /> */}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConfirmProvider>
          <Routes />
        </ConfirmProvider>
        
      </MuiPickersUtilsProvider>
      
    </Fragment>
  );
  
  export default App;