import React, { Fragment, useState } from 'react';

import { Typography, Box, Paper, TextField, FormControl, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { StyledPaper, StyledTextField, ErrorText } from './Styles'
import Spinner from '../../shared/components/Spinner'
import api from '../../shared/utils/api';

const LoginPage = () => {
    const history = useHistory();

    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const login = async (e) => {
        e.preventDefault();
        if (!email || email.trim() === '') {
            setError('Email cannot be empty')
            return
        }

        if (!email || email.trim() === '') {
            setError('Password cannot be empty')
            return
        }

        try {
            setLoading(true)
            let r = await api.post('/api/authenticate', { email, password })
            history.push('/')
        } catch (error) {
            setError(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Fragment>
            {loading && <Spinner></Spinner>}
            <StyledPaper elevation={3}>
                <Box justifyContent="center">
                    <Typography align="center" variant='h3'>
                        NCCAOM Integration Gateway
                    </Typography>
                    <form onSubmit={(e) => login(e)}>

                        <ErrorText id='error-text' variant='p'>{error}</ErrorText>
                        <FormControl fullWidth>
                            
                            <StyledTextField onChange={e => setEmail(e.target.value)} id="filled-basic" label="Email" variant="filled" />
                            
                        </FormControl>
                        <FormControl fullWidth>
                        <StyledTextField onChange={e => setPassword(e.target.value)} id="filled-basic" label="Password" type="password" variant="filled" />

                            
                        </FormControl>
                        <FormControl fullWidth>
                        <Button type="submit" variant="contained" color="primary"><Typography variant='h5'>Login</Typography></Button>

                            
                        </FormControl>

                    </form>
                </Box>
            </StyledPaper>
        </Fragment>
    )
}

export default LoginPage